@import url('./buttons.css');
@import url('./cards.css');
@import url('./inputs.css');

  body {
    max-width: 640px;
    margin: 0 auto;
    padding: 0;
    background-color: #F2F2F2;
    height: 100vh !important;
    overscroll-behavior-y: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
  }

  .App {
    background: #FFF;
    /* height: 100vh; */
  }

/* ~~~~~~~~~~~~~HEADER COMPONENT~~~~~~~~~~~~~ */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #DDD;
  }
  .headerText {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #1A1A1A !important;
  }
  
  /* ~~~~~~~~~~~~~FOOTER COMPONENT~~~~~~~~~~~~~ */
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    border-bottom: 2px solid #A12027;
  }
  .footerText {
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #1A1A1A !important;
    margin-right: 6px;
  }